import React from 'react'
import FinalCardFront from './FinalCardFront';
import FinalCardBack from './FinalCardBack';

type Props = {
          finalCard: any;
          genCard: any;
};

export default function FinalCard({ finalCard, genCard }: Props) {
          return (
                    <div ref={finalCard} className="flex flex-col md:flex-row justify-center items-center gap-2">
                              <FinalCardFront genCard={genCard} />
                              <FinalCardBack genCard={genCard} />
                    </div>
          )
}
