import React from 'react'
import Profile from '../../assets/dpiCardProfile.jpg';
import cardBg from '../../assets/dpiCard.jpg';

type Props = {
          finalCard: any;
          genCard: any;
};

export default function FinalCard({ finalCard, genCard }: Props) {
          const { profile, name, fatherName, motherName, technology, rollNo, session, email } = genCard;
          return (
                    <div className='flex flex-col justify-center items-center py-12 overflow-x-hidden'>
                              <div ref={finalCard} className="card w-[385px] bg-base-100 rounded-none border-[.1px] border-black">
                                        <div>
                                                  <figure><img src={cardBg} alt="" className='relative' /></figure>
                                                  <figure><img src={profile || Profile} className="w-40 h-40 rounded-full border-[3px] border-black absolute top-[9.5rem]" alt="" /></figure>

                                                  <div className='w-full'>
                                                            <div className='w-full flex absolute top-[20rem] font-bold'>
                                                                      <h1 className='text-[17px] select-none ml-6'>Name</h1>
                                                                      <h1 className='text-[17px] capitalize select-none ml-[6.1rem]'>: {name || 'toufiq hasan kiron'}</h1>
                                                            </div>
                                                            <div className='w-full flex absolute top-[21.3rem] font-bold'>
                                                                      <h1 className='text-[17px] select-none ml-6'>Father's Name</h1>
                                                                      <h1 className='text-[17px] capitalize select-none ml-[1.85rem]'>: {fatherName || 'md moyazzem hosan'}</h1>
                                                            </div>
                                                            <div className='w-full flex absolute top-[22.6rem] font-bold'>
                                                                      <h1 className='text-[17px] select-none ml-6'>Mother's Name</h1>
                                                                      <h1 className='text-[17px] capitalize select-none ml-[1.5rem]'>: {motherName || 'bilkis begum'}</h1>
                                                            </div>
                                                            <div className='w-full flex absolute top-[23.9rem] font-bold'>
                                                                      <h1 className='text-[17px] select-none ml-6'>Department</h1>
                                                                      <h1 className='text-[17px] capitalize select-none ml-[3rem]'>: {technology || 'computer'}</h1>
                                                            </div>
                                                            <div className='w-full flex absolute top-[25.2rem] font-bold'>
                                                                      <h1 className='text-[17px] select-none ml-6'>Session</h1>
                                                                      <h1 className='text-[17px] capitalize select-none ml-[5.2rem]'>: {session || '2019-20'}</h1>
                                                            </div>
                                                            <div className='w-full flex absolute top-[26.5rem] font-bold'>
                                                                      <h1 className='text-[17px] select-none ml-6'>Roll</h1>
                                                                      <h1 className='text-[17px] capitalize select-none ml-[7rem]'>: {rollNo || '416020'}</h1>
                                                            </div>
                                                            <div className='w-full flex absolute top-[27.8rem] font-bold'>
                                                                      <h1 className='text-[17px] select-none ml-6'>Email</h1>
                                                                      <h1 className='text-[17px] select-none ml-[6.22rem]'>: {email || 'x@kiron.dev'}</h1>
                                                            </div>

                                                            <div className='w-full flex absolute bottom-7 font-black'>
                                                                      <h1 className='text-[9px] font-signaturex select-none ml-3 text-black'>Golam</h1>
                                                            </div>

                                                            <div className='w-full flex justify-center items-center absolute bottom-4 font-medium'>
                                                                      <h1 className='text-2xl font-theSignature select-none ml-3 text-black rotate-[-4deg]'>Rudaba Naz</h1>
                                                            </div>

                                                            <div className='w-full flex justify-end items-center absolute bottom-7 font-black'>
                                                                      <h1 className='text-[10px] font-signaturex select-none text-black mr-2 -rotate-6'>Kazi Zakir</h1>
                                                            </div>
                                                  </div>
                                        </div>
                              </div>
                    </div>
          )
}
