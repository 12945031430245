import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import Loading from "../../components/Loading";
import auth from "../Firebase/firebase.init";
import useSuperAdmin from "../../hooks/useSuperAdmin";

export default function RequireSuperAdmin({ children }: any) {
  const [user, loading] = useAuthState(auth);
  const [superAdmin, superAdminLoading] = useSuperAdmin(user);
  const location = useLocation();

  if (loading || superAdminLoading) {
    return <Loading />;
  }

  if (!user || !superAdmin) {
    signOut(auth);
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }
  return children;
};
