import React from "react";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { InitializeContext } from "../../../App";
import { BASE_API } from "../../../config";
import auth from "../../../auth/Firebase/firebase.init";
import avatar from "../../../assets/avatar.jpg";
import moment from 'moment';
import useScrollToTop from "../../../hooks/useScrollToTop";

const UserRow = ({ user, index, refetch }: any) => {
  useScrollToTop();
  const { theme } = useContext(InitializeContext);
  const { email, role, uid, image, displayName, lastLogin } = user;

  /* Handle Delete User */
  const handleUserDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      background: theme === "night" ? "#333" : "#fff",
      color: theme === "night" ? "#fff" : "#333",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((willDelete: any) => {
      if (willDelete.isConfirmed) {
        fetch(`${BASE_API}/user/${email}`, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.deletedCount) {
              toast.success(`${email} is deleted.`);
              refetch();
            }
          });
      }
    });
  };

  const makeAdmin = () => {
    fetch(`${BASE_API}/user/admin`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.status === 403) {
          toast.error("Failed to Make an admin");
        }
        return res.json();
      })
      .then((data) => {
        if (data.modifiedCount > 0) {
          refetch();
          Swal.fire({
            title: "Success!",
            text: `${email} is now an admin.`,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  const makeSuperAdmin = () => {
    fetch(`${BASE_API}/user/superAdmin`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.status === 403) {
          toast.error("Failed to Make an admin");
        }
        return res.json();
      })
      .then((data) => {
        if (data.modifiedCount > 0) {
          refetch();
          Swal.fire({
            title: "Success!",
            text: `${email} is now Super Admin.`,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  const removeAdmin = () => {
    fetch(`${BASE_API}/user/removeAdmin`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.status === 403) {
          toast.error("Failed to Remove an admin");
        }
        return res.json();
      })
      .then((data) => {
        if (data.modifiedCount > 0) {
          refetch();
          Swal.fire({
            title: "Success!",
            text: `${email} is user now.`,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  return (
    <tr>
      <th>{index + 1}</th>
      <td>
        {auth?.currentUser?.uid === uid ? (
          <span>
            <img
              src={image ? image : avatar}
              alt={displayName}
              className="rounded-full w-[2.5rem] h-[2.5rem] shadow-sm border-2 border-error p-1"
            />
          </span>
        ) : (
          <span>
            <img
              src={image ? image : avatar}
              alt={displayName}
              className="rounded-full w-[2.5rem] h-[2.5rem] shadow-sm border p-1"
            />
          </span>
        )}
      </td>
      <td className="select-none">
        <span className="tooltip" data-tip={uid ? uid : "Not available"}>
          {displayName ? displayName : "Not Available"}
        </span>
      </td>
      <td className="select-none">{email}</td>
      <td>
        {
          email === "toufiqhasankiron2@gmail.com" || auth?.currentUser?.uid === uid ? (
            <></>
          ) : (
            <span className="tooltip" data-tip="Change user role">
              <select
                className={`select select-bordered select-sm w-full max-w-xs ${role === "admin" ? "select-secondary" : role === "superAdmin" ? "select-info" : ""}`}
                defaultValue={role === "admin" ? "Admin" : role === "superAdmin" ? "Super Admin" : "User"}
                disabled={email === "toufiqhasankiron2@gmail.com"}
                onChange={(e) => {
                  if (e.target.value === "Admin") {
                    makeAdmin();
                  } else if (e.target.value === "Super Admin") {
                    makeSuperAdmin();
                  }
                  else {
                    removeAdmin();
                  }
                }}
              >
                <option disabled selected>
                  Select Role
                </option>
                <option>User</option>
                <option>Admin</option>
                <option>Super Admin</option>
              </select>
            </span>
          )
        }
      </td>
      <td>
        {role === "admin" ? (
          <span className="badge badge-secondary badge-outline p-3 select-none">
            Admin
          </span>
        ) :
          email === "toufiqhasankiron2@gmail.com" ? (
            <td>
              <span className="badge badge-outline p-3 select-none">🔥Developer🔥</span>
            </td>
          )
            : role === "superAdmin" ? (
              <span className="badge badge-info badge-outline p-3 select-none">
                Super Admin
              </span>
            )
              : (
                <span className="badge badge-outline p-3 select-none">
                  User
                </span>
              )}
      </td>
      <td className="select-none">
        {auth?.currentUser?.uid === uid ? (
          <span className="badge badge-error text-white">Active </span>
        ) : (
          ""
        )}
      </td>
      <td>
        <span className="tooltip" data-tip="Last login">
          {
            lastLogin ? (
              <>
                {moment(lastLogin).fromNow()}
              </>
            ) : (
              <span className="badge badge-neutral text-white">Not available</span>
            )
          }
        </span>
      </td>
      <td>
        {email === "toufiqhasankiron2@gmail.com" ||
          auth?.currentUser?.uid === uid ? (
          <></>
        ) : (
          <span className="tooltip tooltip-error" data-tip="Delete user data!">
            <button
              onClick={handleUserDelete}
              className={`btn btn-xs btn-accent text-white ${email !== "toufiqhasankiron2@gmail.com" ? "" : "btn-disabled"}`}
            >
              <i className="bx bxs-trash"></i>
            </button>
          </span>
        )}
      </td>
    </tr>
  );
};

export default UserRow;