import React from 'react'
import Home from '../pages/Home/Home'
import { Outlet } from 'react-router-dom'
import Navbar from '../shared/Navbar'

export default function Root() {
          return (
                    <>
                              <Navbar />
                              <Home />
                              <Outlet />
                    </>
          )
}
