import React from "react";
import Logo from '../../assets/idCard.png';

export default function LoadingScreen() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center justify-center">
        <img src={Logo} alt="logo" className="w-20" />
        <h1 className="text-2xl font-bold mt-2">Fake Card Generator</h1>
        <h1 className="text-2xl font-bold mt-2">is loading...</h1>
        <div className="flex items-center justify-center mt-4">
          <div className="w-3 h-3 bg-primary rounded-full animate-bounce mr-1"></div>
          <div className="w-3 h-3 bg-primary rounded-full animate-bounce mr-1"></div>
          <div className="w-3 h-3 bg-primary rounded-full animate-bounce"></div>
        </div>
      </div>
    </div>
  );
}
