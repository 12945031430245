import React from 'react'
import Profile from '../../assets/me.jpg';
import Principal from '../../assets/principal.jpg';
import Header from '../../assets/headerICST.jpg';

type Props = {
          finalCard: any;
          genCard: any;
};

export default function FinalCard({ finalCard, genCard }: Props) {
          const { profile, name, technology, idNo, session } = genCard;
          return (
                    <div className='flex flex-col justify-center items-center py-12 overflow-x-hidden'>
                              <div ref={finalCard} className="card w-[385px] bg-base-100 rounded-none">
                                        <div className="bg-white border-[1px]">
                                                  <div className='w-full py-2'>
                                                            <figure><img src={Header} className="w-full" alt="" /></figure>
                                                  </div>
                                                  <div>
                                                            <figure><img src={profile || Profile} className="w-[11rem] h-[13.3rem] rounded-lg border-4 border-black" alt="" /></figure>
                                                  </div>
                                                  <div className='py-3 text-black'>
                                                            <h1 className='font-extrabold text-center text-2xl capitalize'>{name || 'toufiq hasan kiron'}</h1>
                                                            <h1 className='font-semibold text-center text-xl'>Technology: {technology || 'Computer'}</h1>
                                                            <h1 className='font-semibold text-center text-xl'>Session: {session || '2019-20'}</h1>
                                                            <p className='font-semibold text-center text-xl'>ID: {idNo || '17666123'}</p>
                                                  </div>
                                                  <div className='pt-2'>
                                                            <figure><img src={Principal} className="w-full" alt="" /></figure>
                                                  </div>
                                        </div>
                              </div>
                    </div>
          )
}
