import React, { useState, useEffect } from 'react'
import ReactToPrint from 'react-to-print';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { Link } from "react-router-dom";
import { toast } from 'react-hot-toast'
import Logo from '../../assets/banner.png';
import FinalCard from './FinalCard';

type Props = {
          finalCard: any;
};

export default function CardForm({ finalCard }: Props) {
          const [genCard, setGenCard] = useState({} as any);
          const [image, setImage] = useState(null);
          const [imageUrl, setImageUrl] = useState(null);
          const [result, setResult] = useState('');

          useEffect(() => {
                    if (image) {
                              setImageUrl(URL.createObjectURL(image) as any);
                    }
          }, [image]);

          const [imageError, setImageError] = useState("");

          const handleImage = (e: any) => {
                    const file = e.target.files[0];
                    const fileType = file.type;

                    const checkFileType = ["image/png", "image/jpeg", "image/jpg", "image/webp", "image/gif"].includes(fileType);

                    // get file width and height
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                              URL.revokeObjectURL(img.src);
                              // check passport size with pixel and file type
                              if (fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/jpg") {
                                        setImage(file);
                                        setImageError("");
                              }
                              // check file type
                              else if (!checkFileType) {
                                        setImageError("Please upload a valid image file");
                              }
                              else {
                                        setImageError("Image size should be less than or equal 600 x 768 pixel");
                              }
                    };
          }

          const [nameError, setNameError] = useState('');
          const [idcError, setIdcError] = useState("");
          const [boardError, setBoardError] = useState("");
          const [regError, setRegError] = useState("");

          const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const name = e.target.value;
                    if (name === "") {
                              setNameError("Name is required");
                    }
                    else if (name.length < 7) {
                              setNameError("Name must be at least 7 characters long");
                    } else {
                              setNameError("");
                    }
          }

          const handleIDCRoll = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const idcRoll = e.target.value;
                    if (idcRoll === "") {
                              setIdcError("IDC Roll is required");
                    }
                    else if (idcRoll.length > 7 || idcRoll.length < 6) {
                              setIdcError("IDC Roll must be 6 or 7 characters long");
                    }
                    else if (idcRoll === "123456" || idcRoll === "654321" || idcRoll === "987654" || idcRoll === "456789" || idcRoll === "000000" || idcRoll === "111111" || idcRoll === "222222" || idcRoll === "333333" || idcRoll === "444444" || idcRoll === "555555" || idcRoll === "666666" || idcRoll === "777777" || idcRoll === "888888" || idcRoll === "999999") {
                              setIdcError("IDC Roll is not valid");
                    }
                    // check roll regex
                    else if (!/^[0-9]*$/.test(idcRoll)) {
                              setIdcError("IDC Roll must be a positive integer");
                    }
                    else {
                              setIdcError("");
                    }
          }

          const handleBoardRoll = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const boardRoll = e.target.value;
                    if (boardRoll === "") {
                              setBoardError("Board Roll is required");
                    }
                    else if (!/^[0-9]*$/.test(boardRoll)) {
                              setBoardError("IDC Roll must be a positive integer");
                    }
                    else if (boardRoll.length > 6 || boardRoll.length < 6) {
                              setBoardError("Board Roll must be 6 digit");
                    }
                    else if (boardRoll === "123456" || boardRoll === "654321" || boardRoll === "987654" || boardRoll === "456789" || boardRoll === "000000" || boardRoll === "111111" || boardRoll === "222222" || boardRoll === "333333" || boardRoll === "444444" || boardRoll === "555555" || boardRoll === "666666" || boardRoll === "777777" || boardRoll === "888888" || boardRoll === "999999") {
                              setBoardError("Board Roll is not valid");
                    }
                    else {
                              setBoardError("");
                    }
          }

          const handleRegNo = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const regNo = e.target.value;
                    if (regNo === "") {
                              setRegError("Registration No is required");
                    }
                    else if (!/^[0-9]*$/.test(regNo)) {
                              setRegError("IDC Roll must be a positive integer");
                    }
                    else if (regNo.length > 10 || regNo.length < 10) {
                              setRegError("Registration No must be 10 digit");
                    }
                    else if (regNo === "1234567890" || regNo === "0978654321" || regNo === "0000000000" || regNo === "1111111111" || regNo === "2222222222" || regNo === "3333333333" || regNo === "4444444444" || regNo === "5555555555" || regNo === "6666666666" || regNo === "7777777777" || regNo === "8888888888" || regNo === "9999999999") {
                              setRegError("Registration is not valid");
                    }
                    else {
                              setRegError("");
                    }
          }

          const handleCreateID = (e: React.SyntheticEvent) => {
                    e.preventDefault();
                    const form = e.target as typeof e.target & {
                              fullName: { value: string };
                              technology: { value: string };
                              idcRoll: { value: string };
                              boardRoll: { value: string };
                              regNo: { value: string };
                              session: { value: string };
                              shift: { value: string };
                              bloodGroup: { value: string };
                    };

                    const profile = imageUrl;
                    const name = form.fullName.value;
                    const technology = form.technology.value;
                    const idcRoll = form.idcRoll.value;
                    const boardRoll = form.boardRoll.value;
                    const regNo = form.regNo.value;
                    const session = form.session.value;
                    const shift = form.shift.value;
                    const bloodGroup = form.bloodGroup.value;

                    if (technology === "Select technology" || !technology) {
                              return toast.error("Please select a technology");
                    }
                    else if (session === "Select session" || !session) {
                              return toast.error("Please select a session");
                    }
                    else if (shift === "Select shift" || !shift) {
                              return toast.error("Please select a shift");
                    }
                    else if (bloodGroup === "Select blood group" || !bloodGroup) {
                              return toast.error("Please select a blood group");
                    }
                    else {
                              setGenCard({ profile, name, technology, idcRoll, boardRoll, regNo, session, shift, bloodGroup });
                              toast.success("ID Card generated successfully..!", {
                                        duration: 4000,
                                        position: 'top-center',
                                        style: {
                                                  background: '#333',
                                                  color: '#fff',
                                                  padding: '15px',
                                        },
                              });
                              setResult("Your ID Card");
                    }


          }

          return (
                    <div className='flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-0'>
                              <div className='py-6 w-full lg:w-2/3'>
                                        <div className='text-center flex flex-col md:flex-row justify-center items-center gap-2'>
                                                  <div className="avatar">
                                                            <div className="w-16 rounded">
                                                                      <Link to="/"><img src={Logo} alt="Logo" /></Link>
                                                            </div>
                                                  </div>
                                                  <h1 className='text-2xl md:text-4xl font-semibold'><Link to="/">Bogura Polytechnic Institute</Link></h1>

                                        </div>
                                        <div className='text-center'>
                                                  <p className='font-semibold text-lg'>Identification Card Generator</p>
                                        </div>
                                        <div className='mt-6 w-full'>
                                                  <form
                                                            onSubmit={handleCreateID}
                                                            className="grid grid-cols-1 gap-3 justify-items-center"
                                                  >
                                                            <div className="relative w-11/12 md:w-10/12 max-w-4xl md:shadow-md rounded-2xl md:p-6">
                                                                      <h3 className="font-semibold text-xl flex justify-center items-center mx-auto badge p-4">
                                                                                Input Your Information
                                                                      </h3>

                                                                      <div className="name border rounded p-3 relative mt-10">
                                                                                <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Put your image</h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${imageError && "border-error shadow-error outline-error"}`}>
                                                                                          <div className="icon">
                                                                                                    <i className="bx bx-image-add"></i>
                                                                                          </div>
                                                                                          <input
                                                                                                    type="file"
                                                                                                    name="image"
                                                                                                    onChange={handleImage}
                                                                                                    className='file-input file-input-ghost focus:outline-none form-control outline-none border-none pl-4 w-full'
                                                                                                    placeholder="Full Name"
                                                                                                    required
                                                                                          />
                                                                                </div>
                                                                                {imageError && (
                                                                                          <small className="flex flex-col pt-2 text-error">
                                                                                                    {imageError}
                                                                                          </small>
                                                                                )}
                                                                      </div>

                                                                      <div className="name border rounded p-3 relative mt-10">
                                                                                <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Put your full name</h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${nameError && "border-error shadow-error outline-error"}`}>
                                                                                          <div className="icon">
                                                                                                    <i className="bx bxs-pen"></i>
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    name="fullName"
                                                                                                    onChange={handleName}
                                                                                                    className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                                    placeholder="Full Name"
                                                                                                    required
                                                                                          />
                                                                                </div>
                                                                                {nameError && (
                                                                                          <small className="flex flex-col pt-2 text-error">
                                                                                                    {nameError}
                                                                                          </small>
                                                                                )}
                                                                      </div>

                                                                      <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Select your technology</h3>
                                                                                          </div>
                                                                                          <div className='input-group flex items-center my-2 border p-3 rounded-md mt-2'>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bx-detail"></i>
                                                                                                    </div>
                                                                                                    <select
                                                                                                              className="select outline-none w-full focus:outline-none"
                                                                                                              name="technology"
                                                                                                              required
                                                                                                    >
                                                                                                              <option disabled selected>
                                                                                                                        Select technology
                                                                                                              </option>
                                                                                                              <option>Civil</option>
                                                                                                              <option>Computer</option>
                                                                                                              <option>Electrical</option>
                                                                                                              <option>Electronics</option>
                                                                                                              <option>Mechanical</option>
                                                                                                              <option>RAC</option>
                                                                                                    </select>
                                                                                          </div>
                                                                                </div>

                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Select your session</h3>
                                                                                          </div>
                                                                                          <div className="input-group flex items-center my-2 border p-3 rounded-md mt-2">
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bx-detail"></i>
                                                                                                    </div>
                                                                                                    <select
                                                                                                              name='session'
                                                                                                              className="select outline-none w-full focus:outline-none"
                                                                                                              required
                                                                                                    >
                                                                                                              <option disabled selected>
                                                                                                                        Select session
                                                                                                              </option>
                                                                                                              <option>2019-20</option>
                                                                                                              <option>2020-21</option>
                                                                                                              <option>2021-22</option>
                                                                                                              <option>2022-23</option>
                                                                                                              <option>2023-24</option>
                                                                                                              <option>2024-25</option>
                                                                                                    </select>
                                                                                          </div>
                                                                                </div>
                                                                      </div>

                                                                      <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Put your ID/C Roll</h3>
                                                                                          </div>
                                                                                          <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${idcError && "border-error shadow-error outline-error"}`}>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bxs-pen"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                              type="number"
                                                                                                              name="idcRoll"
                                                                                                              onChange={handleIDCRoll}
                                                                                                              className='form-control outline-none pl-4 w-full bg-transparent'
                                                                                                              placeholder="ID/C Roll"
                                                                                                              required
                                                                                                    />
                                                                                          </div>
                                                                                          {idcError && (
                                                                                                    <small className="flex flex-col pt-2 text-error">
                                                                                                              {idcError}
                                                                                                    </small>
                                                                                          )}
                                                                                </div>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Put your Board Roll</h3>
                                                                                          </div>
                                                                                          <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${boardError && "border-error shadow-error outline-error"}`}>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bxs-pen"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                              type="number"
                                                                                                              name="boardRoll"
                                                                                                              onChange={handleBoardRoll}
                                                                                                              className={`form-control outline-none pl-4 w-full bg-transparent`}
                                                                                                              placeholder="Board Roll"
                                                                                                              required
                                                                                                    />

                                                                                          </div>
                                                                                          {boardError && (
                                                                                                    <small className="flex flex-col pt-2 text-error">
                                                                                                              {boardError}
                                                                                                    </small>
                                                                                          )}
                                                                                </div>

                                                                      </div>

                                                                      <div className="name border rounded p-3 relative mt-10">
                                                                                <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Put your Registration Number</h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${regError && "border-error shadow-error outline-error"}`}>
                                                                                          <div className="icon">
                                                                                                    <i className="bx bxs-pen"></i>
                                                                                          </div>
                                                                                          <input
                                                                                                    type="number"
                                                                                                    name="regNo"
                                                                                                    onChange={handleRegNo}
                                                                                                    className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                                    placeholder="Registration Number"
                                                                                                    required
                                                                                          />
                                                                                </div>
                                                                                {regError && (
                                                                                          <small className="flex flex-col pt-2 text-error">
                                                                                                    {regError}
                                                                                          </small>
                                                                                )}
                                                                      </div>

                                                                      <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Select your shift</h3>
                                                                                          </div>
                                                                                          <div className="input-group flex items-center my-2 border p-3 rounded-md mt-2">
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bx-detail"></i>
                                                                                                    </div>
                                                                                                    <select
                                                                                                              name='shift'
                                                                                                              className="select outline-none w-full focus:outline-none"
                                                                                                              required
                                                                                                    >
                                                                                                              <option disabled selected>
                                                                                                                        Select shift
                                                                                                              </option>
                                                                                                              <option>First</option>
                                                                                                              <option>Second</option>
                                                                                                    </select>
                                                                                          </div>
                                                                                </div>

                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Select your blood group</h3>
                                                                                          </div>
                                                                                          <div className="input-group flex items-center my-2 border p-3 rounded-md mt-2">
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bx-detail"></i>
                                                                                                    </div>
                                                                                                    <select name='bloodGroup' className="select outline-none w-full focus:outline-none"
                                                                                                              required
                                                                                                              placeholder="Select blood group">
                                                                                                              <option disabled selected>Select blood group</option>
                                                                                                              <option>A+</option>
                                                                                                              <option>A-</option>
                                                                                                              <option>B+</option>
                                                                                                              <option>B-</option>
                                                                                                              <option>O+</option>
                                                                                                              <option>O-</option>
                                                                                                              <option>AB+</option>
                                                                                                              <option>AB-</option>
                                                                                                    </select>
                                                                                          </div>
                                                                                </div>
                                                                      </div>

                                                                      <div className="modal-action">
                                                                                <button className={`btn flex gap-2 ${nameError || imageError || idcError || boardError || regError ? 'btn-disabled' : ''}`} type="submit">
                                                                                          <i className="bx bx-id-card text-lg"></i> Generate ID Card
                                                                                </button>

                                                                      </div>
                                                            </div>
                                                  </form>
                                        </div>
                              </div>

                              <div className='flex flex-col justify-center items-center w-full lg:w-1/3'>
                                        <div className='flex flex-col justify-center items-center w-full'>
                                                  <h1 className='text-xl font-semibold badge p-4'>{result ? result : 'Demo Card'}</h1>

                                                  {
                                                            genCard && (
                                                                      <FinalCard finalCard={finalCard} genCard={genCard} />
                                                            )
                                                  }
                                        </div>
                                        <div className='pb-12 md:py-0 md:pb-12 lg:py-0 flex flex-col md:flex-row justify-start items-start gap-3 md:gap-5'>
                                                  {
                                                            result === "Your ID Card" && (
                                                                      <>
                                                                                <div>
                                                                                          <small className='text-[10px]'>(Not recommended for all case)</small>
                                                                                          <ReactToPrint
                                                                                                    trigger={() => (
                                                                                                              <button className='btn flex gap-2'><i className='bx bx-printer text-lg'></i>Export As PDF</button>
                                                                                                    )}
                                                                                                    content={() => finalCard.current}
                                                                                          />
                                                                                </div>
                                                                                <div>
                                                                                          <small className='text-[10px]'>(Recommended)</small>
                                                                                          <button className='btn flex gap-2' onClick={() => exportComponentAsJPEG(finalCard, { fileName: genCard.name })}><i className='bx bx-image text-lg'></i>
                                                                                                    Export As JPG
                                                                                          </button>
                                                                                </div>
                                                                      </>
                                                            )
                                                  }
                                        </div>
                              </div>
                    </div>
          )
}
