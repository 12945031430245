import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Logo from "../assets/idCard.png";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import { toast } from "react-hot-toast";
import { BiLogInCircle } from "react-icons/bi";
import auth from "../auth/Firebase/firebase.init";
import useAdmin from "../hooks/useAdmin";
import useSuperAdmin from "../hooks/useSuperAdmin";

export default function Navbar() {
  const [user] = useAuthState(auth);
  const { pathname } = useLocation();
  const [admin] = useAdmin(user);
  const [superAdmin] = useSuperAdmin(user);

  const handleLogOut = () => {
    signOut(auth);
    localStorage.removeItem("accessToken");
    toast.success(`Thank you, ${user?.displayName} to stay with us!`, {
      position: "top-center",
      duration: 4000,
    });
  };

  const NavbarMenus = (
    <>
      <div className="py-1 lg:py-0">
        <NavLink
          className="btn flex gap-2 items-center btn-primary text-white"
          to="/dev"
        >
          Developer
        </NavLink>
      </div>
    </>
  );

  return (
    <div className="sticky top-0 w-full z-50 bg-base-100">
      <div
        className='drawer-content flex flex-col backdrop-blur-[18px] bg-base-100 duration-500 shadow-md'
        // hidden navbar on path /developer, /developer/preview, /developer/preview/:id and /developer/preview/:id/:id with includes method
        hidden={pathname.includes("/dev")}
        style={{
          display:
            pathname === "/bpiCard" ||
              pathname === "/bpiCardSA" ||
              pathname === "/icstCard" ||
              pathname === "/dpiCard" ||
              pathname === "/duetCard" ||
              pathname === "/dashboard" ||
              pathname === "/dashboard/manageUsers"
              ? "none"
              : "flex"
        }}
      >
        <div className="navbar py-3 container mx-auto">
          <div className="navbar-start">
            <div className="dropdown">
              <label tabIndex={0} className="btn btn-ghost lg:hidden">
                <HiOutlineMenuAlt4 className="text-3xl text-primary" />
              </label>
              <ul
                tabIndex={0}
                className="menu menu-compact dropdown-content mt-4 p-2 shadow-xl bg-base-100 rounded-box w-[23.5rem] flex flex-wrap justify-center items-center"
              >
                {NavbarMenus}
              </ul>
            </div>
            <Link
              className="btn btn-ghost normal-case text-xl flex gap-2 items-center"
              to="/"
            >
              <img
                src={Logo}
                alt=""
                className={`w-12 ${!user && "ml-[-1.5rem]"}`}
              />
              {!user ? (
                <span className="text-[16px] md:text-2xl">Fake Card Generator</span>
              ) : (
                <span className="text-lg md:text-2xl">
                  Fake Card Generator
                </span>
              )}
            </Link>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal p-0 gap-3">{NavbarMenus}</ul>
          </div>
          <div className="navbar-end gap-3">
            {!user && (
              <NavLink
                to="/login"
                className="btn flex gap-2 items-center btn-primary text-white"
              >
                <BiLogInCircle /> Login
              </NavLink>
            )}
            {user && (
              <div className="flex-none gap-2">
                <div className="dropdown dropdown-end">
                  <label
                    tabIndex={0}
                    className="btn btn-ghost btn-circle avatar"
                  >
                    <div
                      style={{ display: "grid" }}
                      className="w-8 h-8 lg:w-10 lg:h-10 rounded-full border bg-base-300 grid place-items-center ring ring-primary ring-offset-base-100 ring-offset-2"
                    >
                      <img
                        src={auth?.currentUser?.photoURL as any}
                        alt={auth?.currentUser?.displayName?.slice(0, 1) as any}
                      />
                    </div>
                  </label>
                  <ul
                    tabIndex={0}
                    className="mt-5 shadow-xl menu menu-compact dropdown-content bg-base-100 rounded-box w-80"
                  >
                    <div className="w-16 h-16 rounded-full flex items-center justify-center mx-auto my-4 border ring ring-primary ring-offset-base-100 ring-offset-2">
                      <img
                        src={auth?.currentUser?.photoURL as any}
                        alt="profile"
                        className="w-full h-full rounded-full"
                      />
                    </div>
                    <div className="text-center mb-4">
                      <span className="tooltip" data-tip={auth?.currentUser?.email}>
                        <h2 className="font-semibold text-lg">
                          {auth?.currentUser?.displayName}
                        </h2>
                      </span>
                      <p className="text-xs font-medium">
                        User ID: USER-{auth?.currentUser?.uid?.slice(0, 8)}
                      </p>
                      {
                        admin || superAdmin ? (
                          <span className="badge badge-primary badge-outline p-5 mt-4 rounded-full no-animation cursor-default">
                            Welcome, {
                              auth?.currentUser?.email === "toufiqhasankiron2@gmail.com" ? "🔥Developer🔥" : superAdmin ? "Super Admin" : "Admin"
                            }
                          </span>
                        ) :
                          (
                            <></>
                          )
                      }
                      {!admin && !superAdmin && (
                        <>
                          <p className="font-medium text-error pt-4 pb-2 px-4">
                            You need to be an admin to access this page. Please contact your Super Admin. Thank you!
                          </p>
                          <Link to="/developer" className="btn btn-primary mt-4 rounded-full no-animation text-white">
                            Contact Us
                          </Link>
                        </>
                      )}
                    </div>
                    <hr className="font-semibold" />
                    {superAdmin && (
                      <>
                        <li className="py-1 font-semibold">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "text-white uppercase bg-primary" : "uppercase"
                            }
                            to="/dashboard"
                          >
                            <i className="bx bxs-dashboard text-lg"></i> Dashboard
                          </NavLink>
                        </li>
                      </>
                    )}
                    {auth?.currentUser?.email === 'toufiqhasankiron2@gmail.com' && (
                      <>
                        <li className="py-1 font-semibold">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? "text-white uppercase bg-primary" : "uppercase"
                            }
                            to="/dashboard/manageUsers"
                          >
                            <i className="bx bxs-user text-lg"></i> Manage Users
                          </NavLink>
                        </li>
                      </>
                    )}
                    {admin && (
                      <li className="py-1 font-semibold">
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "text-white uppercase bg-primary" : "uppercase"
                          }
                          to="/bpiCard"
                        >
                          <i className="bx bx-id-card text-lg"></i> Generate ID Card
                        </NavLink>
                      </li>
                    )}
                    <li className="pb-1">
                      <button
                        onClick={handleLogOut}
                        className="pb-3 font-semibold uppercase"
                      >
                        <i className="bx bx-log-out text-lg"></i>
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
