import React, { createContext, useState, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import CardGen from './pages/CardForm/CardGen';
import LoadingScreen from './shared/LoadingScreen/LoadingScreen';
import ThemeChanger from './shared/ThemeChanger/ThemeChanger';
import NotFound from './shared/NotFound/NotFound';
import Login from './pages/Authentication/Login/Login';
import RequireAdmin from './auth/RequireAdmin/RequireAdmin';
import RequireAuth from './auth/RequireAuth/RequireAuth';
import Dev from './pages/Dev/Dev';
import Dashboard from './pages/Dashboard/Dashboard/Dashboard';
import RequireSuperAdmin from './auth/RequireSuperAdmin/RequireSuperAdmin';
import Welcome from './pages/Dashboard/Welcome/Welcome';
import ManageUsers from './pages/Dashboard/ManageUsers/ManageUsers';
import CardGenICST from './pages/ICSTCardForm/CardGenICST';
import CardGenDPI from './pages/DPICardForm/CardGenDPI';
import CardGenDUET from './pages/DUETCardForm/CardGenDUET';
import Root from './Layouts/Root';

export const InitializeContext = createContext(null as any);

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
    },
    {
      path: '/bpiCard',
      element:
        <>
          <RequireAuth>
            <RequireAdmin>
              <CardGen />
            </RequireAdmin>
          </RequireAuth>
        </>,
    },
    {
      path: '/bpiCardSA',
      element: <>
        <RequireAuth>
          <RequireSuperAdmin>
            <CardGen />
          </RequireSuperAdmin>
        </RequireAuth>
      </>,
    },
    {
      path: '/icstCard',
      element: <>
        <RequireAuth>
          <RequireSuperAdmin>
            <CardGenICST />
          </RequireSuperAdmin>
        </RequireAuth>
      </>,
    },
    {
      path: '/dpiCard',
      element: <>
        <RequireAuth>
          <RequireSuperAdmin>
            <CardGenDPI />
          </RequireSuperAdmin>
        </RequireAuth>
      </>,
    },
    {
      path: '/duetCard',
      element: <>
        <RequireAuth>
          <RequireSuperAdmin>
            <CardGenDUET />
          </RequireSuperAdmin>
        </RequireAuth>
      </>,
    },
    {
      path: '/Login',
      element: <Login />,
    },
    {
      path: '/dev',
      element: <Dev />,
    },
    {
      path: '/dashboard',
      element: <RequireSuperAdmin>
        <Dashboard />
      </RequireSuperAdmin>,
      children: [
        {
          path: '/dashboard',
          element: <Welcome />,
        },
        {
          path: '/dashboard/manageUsers',
          element: <ManageUsers />,
        },
        {
          path: '*',
          element: <NotFound />
        }
      ],
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]
);

export default function App() {
  const [theme, setTheme] = useState<string>("emerald");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setTheme(window.localStorage.getItem("theme") as any);
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <InitializeContext.Provider value={{ theme, setTheme }}>
      <div data-theme={theme ? theme : "emerald"} className="bg-base-100">
        {
          loading ? (
            <LoadingScreen />
          ) :
            (
              <>
                <RouterProvider router={router} />
                <Toaster />
                {loading ? null : <ThemeChanger />}
              </>
            )
        }
      </div>
    </InitializeContext.Provider>
  );
}
