import { useContext, useEffect, useState } from "react";
import { InitializeContext } from "../App";

export default function useTitle(titleText: any) {
  const { appName } = useContext(InitializeContext);
  const [title, setTitle] = useState("");
  useEffect(() => {
    document.title = titleText + " - Fake Card Generator";
    setTitle(titleText);
  }, [titleText, appName]);
  return [title];
};
