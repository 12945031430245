import { useEffect, useState } from "react";
import { BASE_API } from "../config";

export default function useSuperAdmin(user: any){
  const [superAdmin, setSuperAdmin] = useState(false);
  const [superAdminLoading, setSuperAdminLoading] = useState(true);
  useEffect(() => {
    const email = user?.email;
    if (email) {
      fetch(`${BASE_API}/superAdmin/${email}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setSuperAdmin(data.superAdmin);
          setSuperAdminLoading(false);
        });
    }
  }, [user]);

  return [superAdmin, superAdminLoading];
};