import React, { useRef } from 'react'
import useScrollToTop from '../../hooks/useScrollToTop';
import useTitle from '../../hooks/useTitle';
import CardForm from './CardForm';
import Footer from '../../shared/Footer';

type Props = {};

export default function CardGen(props: Props) {
          useTitle("Card Generator");
          useScrollToTop();
          const finalCard = useRef(null);
          return (
                    <>
                              <CardForm finalCard={finalCard} />
                              <Footer />
                    </>
          )
}
