import React, { useState, useEffect } from 'react'
import ReactToPrint from 'react-to-print';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { Link } from "react-router-dom";
import { toast } from 'react-hot-toast'
import Logo from '../../assets/dpiLogo.png';
import FinalCard from './FinalCard';

type Props = {
          finalCard: any;
};

export default function CardForm({ finalCard }: Props) {
          const [genCard, setGenCard] = useState({} as any);
          const [image, setImage] = useState(null);
          const [imageUrl, setImageUrl] = useState(null);
          const [result, setResult] = useState('');

          useEffect(() => {
                    if (image) {
                              setImageUrl(URL.createObjectURL(image) as any);
                    }
          }, [image]);

          const [imageError, setImageError] = useState("");

          const handleImage = (e: any) => {
                    const file = e.target.files[0];
                    const fileType = file.type;

                    const checkFileType = ["image/png", "image/jpeg", "image/jpg", "image/webp", "image/gif"].includes(fileType);

                    // get file width and height
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                              URL.revokeObjectURL(img.src);
                              // check passport size with pixel and file type
                              if (fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/jpg") {
                                        setImage(file);
                                        setImageError("");
                              }
                              // check file type
                              else if (!checkFileType) {
                                        setImageError("Please upload a valid image file");
                              }
                              else {
                                        setImageError("");
                              }
                    };
          }

          const [nameError, setNameError] = useState("");
          const [fatherNameError, setFatherNameError] = useState("");
          const [motherNameError, setMotherNameError] = useState("");
          const [rollError, setRollError] = useState("");
          const [emailError, setEmailError] = useState("");

          const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const name = e.target.value;
                    if (name === "") {
                              setNameError("Name is required");
                    }
                    else if (name.length > 22) {
                              setNameError("Name must be less than 22 characters long");
                    } else {
                              setNameError("");
                    }
          }

          const handleFatherName = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const fatherName = e.target.value;
                    if (fatherName === "") {
                              setFatherNameError("Father's name is required");
                    }
                    else if (fatherName.length > 22) {
                              setFatherNameError("Father's name must be less than 22 characters long");
                    } else {
                              setFatherNameError("");
                    }
          }

          const handleMotherName = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const motherName = e.target.value;
                    if (motherName === "") {
                              setMotherNameError("Mother's name is required");
                    }
                    else if (motherName.length > 22) {
                              setMotherNameError("Mother's name must be less than 22 characters long");
                    } else {
                              setMotherNameError("");
                    }
          }

          const handleRollDNo = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const rollNo = e.target.value;
                    if (rollNo === "") {
                              setRollError("Roll No is required");
                    }
                    else if (!/^[0-9]*$/.test(rollNo)) {
                              setRollError("Roll No must be a positive integer");
                    }
                    else if (rollNo.length > 6 || rollNo.length < 6) {
                              setRollError("Roll No must be 6 digit");
                    }
                    else if (rollNo === "000000") {
                              setRollError("Roll No is not valid");
                    }
                    else {
                              setRollError("");
                    }
          }

          const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const email = e.target.value;
                    if (email === "") {
                              setEmailError("Email is required");
                    }
                    else if (email.length > 21) {
                              setEmailError("Email must be at least 21 characters long");
                    }
                    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
                              setEmailError("Invalid email address");
                    }
                    else {
                              setEmailError("");
                    }
          }

          const handleCreateID = (e: React.SyntheticEvent) => {
                    e.preventDefault();
                    const form = e.target as typeof e.target & {
                              fullName: { value: string };
                              fatherName: { value: string };
                              motherName: { value: string };
                              technology: { value: string };
                              rollNo: { value: string };
                              session: { value: string };
                              email: { value: string };
                    };

                    const profile = imageUrl;
                    const name = form.fullName.value;
                    const fatherName = form.fatherName.value;
                    const motherName = form.motherName.value;
                    const rollNo = form.rollNo.value;
                    const technology = form.technology.value;
                    const session = form.session.value;
                    const email = form.email.value;

                    if (technology === "Select technology" || !technology) {
                              return toast.error("Please select a technology");
                    }
                    else if (session === "Select session" || !session) {
                              return toast.error("Please select a session");
                    }
                    else {
                              setGenCard({ profile, name, fatherName, motherName, technology, rollNo, session, email });
                              toast.success("ID Card generated successfully..!", {
                                        duration: 4000,
                                        position: 'top-center',
                                        style: {
                                                  background: '#333',
                                                  color: '#fff',
                                                  padding: '15px',
                                        },
                              });
                              setResult("Your ID Card");
                    }


          }

          return (
                    <div className='flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-0'>
                              <div className='py-6 w-full lg:w-2/3'>
                                        <div className='text-center flex flex-col md:flex-row justify-center items-center gap-2'>
                                                  <div className="avatar">
                                                            <div className="w-16 rounded">
                                                                      <Link to="/"><img src={Logo} alt="Logo" /></Link>
                                                            </div>
                                                  </div>
                                                  <h1 className='text-2xl md:text-4xl font-semibold'><Link to="/">Dhaka Polytechnic Institute</Link></h1>

                                        </div>
                                        <div className='text-center'>
                                                  <p className='font-semibold text-lg'>Identification Card Generator</p>
                                        </div>
                                        <div className='mt-4 w-full'>
                                                  <form
                                                            onSubmit={handleCreateID}
                                                            className="grid grid-cols-1 gap-3 justify-items-center"
                                                  >
                                                            <div className="relative w-11/12 md:w-10/12 max-w-4xl md:shadow-md rounded-2xl md:p-6">
                                                                      <h3 className="font-semibold text-xl flex justify-center items-center mx-auto badge p-4">
                                                                                Input Your Information
                                                                      </h3>

                                                                      <div className="name border rounded p-3 relative mt-10">
                                                                                <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Put your image</h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${imageError && "border-error shadow-error outline-error"}`}>
                                                                                          <div className="icon">
                                                                                                    <i className="bx bx-image-add"></i>
                                                                                          </div>
                                                                                          <input
                                                                                                    type="file"
                                                                                                    name="image"
                                                                                                    onChange={handleImage}
                                                                                                    className='file-input file-input-ghost form-control focus:outline-none border-none pl-4 w-full'
                                                                                                    placeholder="Full Name"
                                                                                                    required
                                                                                          />
                                                                                </div>
                                                                                {imageError && (
                                                                                          <small className="flex flex-col pt-2 text-error">
                                                                                                    {imageError}
                                                                                          </small>
                                                                                )}
                                                                      </div>

                                                                      <div className="name border rounded p-3 relative mt-10">
                                                                                <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Put your full name</h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${nameError && "border-error shadow-error outline-error"}`}>
                                                                                          <div className="icon">
                                                                                                    <i className="bx bxs-pen"></i>
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    name="fullName"
                                                                                                    onChange={handleName}
                                                                                                    className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                                    placeholder="Full Name"
                                                                                                    required
                                                                                          />
                                                                                </div>
                                                                                {nameError && (
                                                                                          <small className="flex flex-col pt-2 text-error">
                                                                                                    {nameError}
                                                                                          </small>
                                                                                )}
                                                                      </div>
                                                                      <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Put your Father's name</h3>
                                                                                          </div>
                                                                                          <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${fatherNameError && "border-error shadow-error outline-error"}`}>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bxs-pen"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                              type="text"
                                                                                                              name="fatherName"
                                                                                                              onChange={handleFatherName}
                                                                                                              className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                                              placeholder="Father's Name"
                                                                                                              required
                                                                                                    />
                                                                                          </div>
                                                                                          {fatherNameError && (
                                                                                                    <small className="flex flex-col pt-2 text-error">
                                                                                                              {fatherNameError}
                                                                                                    </small>
                                                                                          )}
                                                                                </div>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Put your Mother's name</h3>
                                                                                          </div>
                                                                                          <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${motherNameError && "border-error shadow-error outline-error"}`}>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bxs-pen"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                              type="text"
                                                                                                              name="motherName"
                                                                                                              onChange={handleMotherName}
                                                                                                              className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                                              placeholder="Mother's Name"
                                                                                                              required
                                                                                                    />
                                                                                          </div>
                                                                                          {motherNameError && (
                                                                                                    <small className="flex flex-col pt-2 text-error">
                                                                                                              {motherNameError}
                                                                                                    </small>
                                                                                          )}
                                                                                </div>
                                                                      </div>

                                                                      <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Select your technology</h3>
                                                                                          </div>
                                                                                          <div className='input-group flex items-center my-2 border p-3 rounded-md mt-2'>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bx-detail"></i>
                                                                                                    </div>
                                                                                                    <select
                                                                                                              className="select outline-none w-full focus:outline-none"
                                                                                                              name="technology"
                                                                                                              required
                                                                                                    >
                                                                                                              <option disabled selected>
                                                                                                                        Select technology
                                                                                                              </option>
                                                                                                              <option>Civil</option>
                                                                                                              <option>Computer</option>
                                                                                                              <option>Electrical</option>
                                                                                                              <option>Electronics</option>
                                                                                                              <option>Mechanical</option>
                                                                                                              <option>RAC</option>
                                                                                                    </select>
                                                                                          </div>
                                                                                </div>

                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Select your session</h3>
                                                                                          </div>
                                                                                          <div className="input-group flex items-center my-2 border p-3 rounded-md mt-2">
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bx-detail"></i>
                                                                                                    </div>
                                                                                                    <select
                                                                                                              name='session'
                                                                                                              className="select outline-none w-full focus:outline-none"
                                                                                                              required
                                                                                                    >
                                                                                                              <option disabled selected>
                                                                                                                        Select session
                                                                                                              </option>
                                                                                                              <option>2017-18</option>
                                                                                                              <option>2018-19</option>
                                                                                                              <option>2019-20</option>
                                                                                                              <option>2020-21</option>
                                                                                                              <option>2021-22</option>
                                                                                                              <option>2022-23</option>
                                                                                                              <option>2023-24</option>
                                                                                                    </select>
                                                                                          </div>
                                                                                </div>
                                                                      </div>

                                                                      <div className='flex flex-col md:flex-row justify-center items-center gap-2 w-full'>
                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Put your Roll No</h3>
                                                                                          </div>
                                                                                          <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${rollError && "border-error shadow-error outline-error"}`}>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bxs-pen"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                              type="number"
                                                                                                              name="rollNo"
                                                                                                              onChange={handleRollDNo}
                                                                                                              className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                                              placeholder="Roll No"
                                                                                                              required
                                                                                                    />
                                                                                          </div>
                                                                                          {rollError && (
                                                                                                    <small className="flex flex-col pt-2 text-error">
                                                                                                              {rollError}
                                                                                                    </small>
                                                                                          )}
                                                                                </div>

                                                                                <div className="name border rounded p-3 relative mt-10 w-full">
                                                                                          <div className="name-title absolute -top-4 bg-base-100 border rounded p-1">
                                                                                                    <h3 className="text-xs font-poppins">Put your email</h3>
                                                                                          </div>
                                                                                          <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2 ${emailError && "border-error shadow-error outline-error"}`}>
                                                                                                    <div className="icon">
                                                                                                              <i className="bx bxs-pen"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                              type="text"
                                                                                                              name="email"
                                                                                                              onChange={handleEmail}
                                                                                                              className="form-control outline-none pl-4 w-full bg-transparent"
                                                                                                              placeholder="Email"
                                                                                                              required
                                                                                                    />
                                                                                          </div>
                                                                                          {emailError && (
                                                                                                    <small className="flex flex-col pt-2 text-error">
                                                                                                              {emailError}
                                                                                                    </small>
                                                                                          )}
                                                                                </div>
                                                                      </div>

                                                                      <div className="modal-action">
                                                                                <button className={`btn flex gap-2 ${nameError || fatherNameError || motherNameError || imageError || rollError || emailError ? 'btn-disabled' : ''}`} type="submit">
                                                                                          <i className="bx bx-id-card text-lg"></i> Generate ID Card
                                                                                </button>
                                                                      </div>
                                                            </div>
                                                  </form>
                                        </div>
                              </div>

                              <div className='flex flex-col justify-center items-center w-full lg:w-1/3'>
                                        <div className='flex flex-col justify-center items-center w-full'>
                                                  <h1 className='text-xl font-semibold badge p-4'>{result ? result : 'Demo Card'}</h1>

                                                  {
                                                            genCard && (
                                                                      <FinalCard finalCard={finalCard} genCard={genCard} />
                                                            )
                                                  }
                                        </div>

                                        <div className='pb-12 md:py-0 md:pb-12 lg:py-0 flex flex-col md:flex-row justify-start items-start gap-3 md:gap-5'>
                                                  {
                                                            result === "Your ID Card" && (
                                                                      <>
                                                                                <div>
                                                                                          <small className='text-[10px]'>(Not recommended for all case)</small>
                                                                                          <ReactToPrint
                                                                                                    trigger={() => (
                                                                                                              <button className='btn flex gap-2'><i className='bx bx-printer text-lg'></i>Export As PDF</button>
                                                                                                    )}
                                                                                                    content={() => finalCard.current}
                                                                                          />
                                                                                </div>
                                                                                <div>
                                                                                          <small className='text-[10px]'>(Recommended)</small>
                                                                                          <button className='btn flex gap-2' onClick={() => exportComponentAsJPEG(finalCard, { fileName: genCard.name })}><i className='bx bx-image text-lg'></i>
                                                                                                    Export As JPG
                                                                                          </button>
                                                                                </div>
                                                                      </>
                                                            )
                                                  }
                                        </div>
                              </div>
                    </div>
          )
}
