import React from 'react'
import Profile from '../../assets/me.jpg';
import cardBg from '../../assets/duetFrontBg.jpg';

type Props = {
          genCard: any;
};

export default function FinalCardFront({ genCard }: Props) {
          const { name, idNo, session, technology, profile } = genCard;

          const handleSessionValidity = (session: string) => {
                    if (session === '2019-20') {
                              return '2023';
                    } else if (session === '2020-21') {
                              return '2024';
                    } else if (session === '2021-22') {
                              return '2025';
                    } else if (session === '2022-23') {
                              return '2026';
                    } else if (session === '2023-24') {
                              return '2027';
                    } else if (session === '2024-25') {
                              return '2028';
                    } else {
                              return '2023';
                    }
          }
          return (
                    <div className='flex flex-col justify-center items-center py-12 overflow-x-hidden'>
                              <div className="card w-[385px] bg-base-100 rounded-none border-2">
                                        <div>
                                                  <figure><img src={cardBg} alt="" className='relative' /></figure>
                                                  <figure><img src={profile || Profile} className="w-[7.2rem] h-[8.7rem] ml-24 mt-3 border-[2px] border-black absolute top-[9.5rem] shadow-lg" alt="" /></figure>

                                                  <div className='w-full absolute top-[19.5rem]'>
                                                            <h1 className='text-[19px] font-bold text-center capitalize select-none ml-[6.1rem] pb-3'>{name || 'toufiq hasan kiron'}</h1>
                                                            <h1 className='text-[18px] font-bold text-center capitalize select-none ml-[6.1rem]'>B. Sc. Eng. Programme</h1>
                                                            <h1 className='text-[18px] font-bold text-center capitalize select-none ml-[6.1rem]'>Department of</h1>
                                                            <h1 className='text-[18px] font-bold text-center capitalize select-none ml-[6.1rem]'>{technology || 'Computer'} Engineering</h1>
                                                            <h1 className='text-[18px] font-bold text-center select-none ml-[6.1rem]'>Session {session || '2019-20'}</h1>
                                                            <h1 className='text-[17px] font-bold text-center select-none ml-[6.1rem] text-red-600'>Valid up to {session ? handleSessionValidity(session) : '2023'}</h1>
                                                  </div>

                                                  <h1 className='absolute bottom-0 right-2 text-[19px] font-bold text-center select-none'>ID S {idNo || '416020'}</h1>
                                        </div>
                              </div>
                    </div>
          )
}
