import React from 'react'
import Logo from '../../assets/logo.png';
import Profile from '../../assets/me.jpg';
import Registrar from '../../assets/registrar.png';
import Principal from '../../assets/principal.png';
import Header from '../../assets/header.png';

type Props = {
  finalCard: any;
  genCard: any;
};

export default function FinalCard({ finalCard, genCard }: Props) {
  const { profile, name, technology, idcRoll, boardRoll, regNo, session, shift, bloodGroup } = genCard;

  const handleSessionValidity = (session: string) => {
    if (session === '2019-20') {
      return '31-07-2023';
    } else if (session === '2020-21') {
      return '31-07-2024';
    } else if (session === '2021-22') {
      return '31-07-2025';
    } else if (session === '2022-23') {
      return '31-07-2026';
    } else if (session === '2023-24') {
      return '31-07-2027';
    } else if (session === '2024-25') {
      return '31-07-2028';
    } else {
      return '31-07-2023';
    }
  }

  return (
    <div className='flex flex-col justify-center items-center py-12 overflow-x-hidden'>
      <div ref={finalCard} className="card w-[375px] bg-base-100 rounded-none">
        <div className="card-body text-black bg-gradient-to-r from-[#fdf2e3] to-[#ecfdf3] border-[.5px]">
          <div className='w-full'>
            <figure><img src={Header} draggable={false} className="w-full select-none" alt="" /></figure>
          </div>
          <div className='flex justify-between items-center'>
            <figure><img src={Logo} draggable={false} className="select-none w-20" alt="" /></figure>
            <span className='p-1 text-2xl text-white font-bold rounded-xl bg-blue-900 select-none'>ID CARD</span>
            <figure><img src={profile || Profile} draggable={false} className="w-[6rem] h-[7.5rem] border-2 border-black" alt="" /></figure>
          </div>
          <div className='flex justify-between'>
            <h1 className='font-nimbusRomanBold text-center text-lg select-none'>Name</h1>
            <h1 className='font-nimbusRomanBold text-center text-lg uppercase select-none'>: {name || 'toufiq hasan kiron'}</h1>
          </div>
          <h1 className='font-nimbusRomanRegular text-[#972158] text-md select-none'>Technology: {technology || 'Computer'}</h1>
          <div className='flex justify-between'>
            <h1 className='font-nimbusRomanRegular text-md select-none'>ID/C - Roll: {idcRoll || '669152'}</h1>
            <h1 className='font-nimbusRomanRegular text-md select-none'>Board Roll: {boardRoll || '416020'}</h1>
          </div>
          <div className='flex justify-between'>
            <h1 className='font-nimbusRomanRegular text-md select-none'>Reg No: {regNo || '1502045309'}</h1>
            <h1 className='font-nimbusRomanRegular text-md select-none'>Session: {session || '2019-20'}</h1>
          </div>
          <div className='flex justify-between'>
            <h1 className='font-nimbusRomanRegular text-md select-none'>Shift: {shift || 'First'}</h1>
            <h1 className='font-nimbusRomanRegular text-md select-none'>Validity: {session ? handleSessionValidity(session) : '31-07-2023'}</h1>
          </div>
          <p className='font-nimbusRomanRegular text-md select-none'>Blood Group: {bloodGroup || 'B+'}</p>
          <div className='flex justify-between items-center gap-[200px]'>
            <p className='font-nimbusRomanRegular text-sm w-full flex flex-col justify-center items-center'>
              <figure><img src={Registrar} draggable={false} className="w-8" alt="" /></figure>
              <span className='select-none'>Registrar</span>
            </p>
            <p className='font-nimbusRomanRegular text-sm w-full flex flex-col justify-center items-center'>
              <figure><img src={Principal} draggable={false} className="w-12" alt="" /></figure>
              <span className='select-none'>Principal</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
