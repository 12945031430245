import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom'
import bannerImg from '../../assets/idCard.png'
import auth from '../../auth/Firebase/firebase.init';
import useAdmin from '../../hooks/useAdmin';
import useScrollToTop from '../../hooks/useScrollToTop';
import useSuperAdmin from '../../hooks/useSuperAdmin';
import useTitle from '../../hooks/useTitle';
import Footer from '../../shared/Footer';

const Fade = require("react-reveal/Fade");

export default function Home() {
          useTitle("Home");
          useScrollToTop();
          const [user] = useAuthState(auth);
          const [admin] = useAdmin(user);
          const [superAdmin] = useSuperAdmin(user);

          return (
                    <>
                              <section
                                        className="bg-base-100 body-font md:py-16"
                              >
                                        <div className="hero pb-10 md:pb-20 bg-base-100">
                                                  <div className="hero-content flex-col justify-between lg:flex-row-reverse">
                                                            <Fade right distance="20px">
                                                                      <div className="w-full lg:w-1/2 rounded overflow-hidden lg:ml-6">
                                                                                <div className="outline-none h-full">
                                                                                          <img
                                                                                                    src={bannerImg}
                                                                                                    className="w-full mx-auto md:w-[50%] lg:w-full"
                                                                                                    alt=""
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </Fade>
                                                            <Fade left distance="30px">
                                                                      <div className="lg:w-1/2 pt-11 lg:pt-0 leading-loose">
                                                                                <span className="text-lg font-semibold">
                                                                                          Welcome to{" "}
                                                                                          <strong className="text-primary"><span className='hidden md:inline'>Fake</span> Student ID Card Generator</strong>.
                                                                                </span>
                                                                                <h1 className="text-3xl md:text-5xl font-bold leading-tight">
                                                                                          Generate your Student ID Card in seconds. {" "}
                                                                                          <span className="text-primary">No need to go to registrar office</span>.
                                                                                </h1>
                                                                                <p className="py-6">
                                                                                          This is a web application that allows you to generate your <span className='text-primary'><a href="https://bogurapoly.gov.bd" target="_blank" rel="noreferrer">Bogura Polytechnic Institute</a></span>, <span className='text-primary'><a href="https://dpi.gov.bd" target="_blank" rel="noreferrer">Dhaka Polytechnic Institute</a></span>, <span className='text-primary'><a href="https://icstfeni.edu.bd" target="_blank" rel="noreferrer">Institute of Computer Science & Technology</a></span> and <span className='text-primary'><a href="https://duet.ac.bd" target="_blank" rel="noreferrer">
Dhaka University of Engineering & Technology</a></span> Student's ID Card in seconds. Just fill out the form and you're good to go. You can also download your ID Card as a PDF file & as JPG file. This project was developed by <Link to="/developer"><span className='text-primary font-goodVibrationsScript'>Toufiq Hasan Kiron</span></Link>.
                                                                                </p>
                                                                                {admin ? (
                                                                                          <div>
                                                                                                    <Link to="/bpiCard" className="btn btn-primary text-white">
                                                                                                              {auth?.currentUser?.email ? "Let's Create Card" : "Login to Get Started"}
                                                                                                    </Link>
                                                                                          </div>
                                                                                ) : superAdmin ? (
                                                                                          <Link to="/bpiCardSA" className="btn btn-primary text-white">
                                                                                                    {auth?.currentUser?.email ? "Let's Create Card" : "Login to Get Started"}
                                                                                          </Link>
                                                                                ) : (
                                                                                          <>
                                                                                          </>
                                                                                )}
                                                                      </div>
                                                            </Fade>
                                                  </div>
                                        </div>
                              </section>
                              <Footer />
                    </>
          )
}
